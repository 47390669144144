import { VueEditor } from 'vue2-editor';
import { mapActions, mapState } from 'vuex';
import { getStatus } from '@/helpers/statusAgreement.js';
import QrDocument from '@/components/agreement/elements/QrDocument/QrDocument.vue';
import DocumentDialog from '@/components/agreement/elements/DocumentDialog/DocumentDialog.vue';
import ContractSheetDialog from '@/components/agreement/elements/ContractSheetDialog/ContractSheetDialog.vue';

import ObservationsTable from '@/components/agreement/elements/ObservationsTable/ObservationsTable.vue';
import NotificationUsersDialog from '@/components/agreement/elements/NotificationUsersDialog/NotificationUsersDialog.vue';
import ListObservations from '@/components/agreement/elements/ListObservations/ListObservations.vue';
import DocumentsSection from '@/components/agreement/elements/DocumentsSection/DocumentsSection.vue';
import ContractSheetTable from '@/components/agreement/elements/ContractSheetTable/ContractSheetTable.vue';
import ApproversSection from '@/components/agreement/elements/ApproversSection/ApproversSection.vue';
import DownloadFileBtn from '@/components/agreement/elements/DownloadFileBtn/DownloadFileBtn.vue';
import AnnexSection from '@/components/agreement/elements/AnnexSection/AnnexSection.vue';
import SupplierAssignmentDialog from '@/components/agreement/elements/SupplierAssignmentDialog/SupplierAssignmentDialog.vue';
import LegalSubmitButton from '@/components/agreement/elements/LegalSubmitButton/LegalSubmitButton.vue';
import SendToLegalBox from '@/components/supplier/elements/SendToLegalBox/SendToLegalBox.vue';
import TemplateChangesTable from '@/components/agreement/elements/TemplateChangesTable/TemplateChangesTable.vue';
import { isObject, showSnack, formatDate } from '@/helpers/globalHelpers';

export default {
	name: 'ReadAgreementPage',
	props: {
		module: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			document: null,
			loadingData: false,
			dialogSheet: false,
			dialogDocument: false,
			dialogSheetEdit: false,
			isCompleteAnnexes: false,
			openNotificationUsersDialog: false,
			config: {
				theme: 'snow',
				modules: {
					toolbar: false,
				},
			},
			headers: [
				{
					text: 'Modificado',
					value: 'created_at',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Añadido por:',
					value: 'user.fullName',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: 'Comentarios',
					value: 'comment',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
				{
					text: '',
					value: 'actions',
					sortable: false,
					align: 'left',
					class: 'primary--text',
				},
			],
		};
	},
	created() {
		this.readAgreement();
	},
	mounted() {},
	computed: {
		...mapState('agreement', ['agreement']),

		agreementId() {
			return this.$route.params?.agreementId || null;
		},

		isEditable() {
			const routeName = this.$route.name;
			const agreementStatus = this.agreement?.status;
			if (routeName === 'SupplierPrecontractDetail') {
				return ['PROVEEDOR_APROBACION'].includes(agreementStatus);
			}
		},

		disabledSendLegal() {
			return (
				this.$store.state.agreement?.flagRelevantChanges &&
				this.agreement?.status === 'APPROVED_PROVIDER'
			);
		},

		readDocumentButtonSettings() {
			const routeName = this?.$route.name;
			let name = '';
			if (routeName === 'SupplierPrecontractDetail') {
				name = 'SupplierReadPrecontract';
			} else if (routeName === 'ReadAgreement') {
				name = 'ReadPrecontract';
			}
			return {
				name,
				params: { agreementId: this.agreement.id },
			};
		},

		isEnabledForSupply: function () {},
	},
	watch: {},
	methods: {
		...mapActions('agreement', [
			'getAgreement',
			'cleanAgreement',
			'createAgreementNotification',
			'readAgreementNotification',
			'updateAgreementNotification',
		]),
		getStatus,
		formatDate,

		async readAgreement() {
			if (this.agreementId) {
				this.loadingData = true;
				const { ok, error } = await this.getAgreement(this.agreementId);
				if (!ok) {
					showSnack(error?.data?.message, 'error');
				}
				this.loadingData = false;
			}
		},

		getField(key) {
			const fields =
				Array.isArray(this.agreement?.agreementForm) &&
				this.agreement?.agreementForm;
			const found = fields.find((e) => e?.fieldAgreement?.key == key);
			return found?.value || null;
		},

		openDocumentDialog(v) {
			this.document = v;
			this.dialogDocument = true;
		},

		printSupplierUser() {
			const supplierUser = this.agreement?.user_provider_id;
			if (isObject(supplierUser)) {
				return `${supplierUser?.name || ''} ${
					supplierUser?.last_name_father || ''
				}`;
			}
			return '-';
		},
	},
	beforeDestroy() {
		this.cleanAgreement();
	},
	components: {
		VueEditor,
		ContractSheetDialog,
		DocumentDialog,
		QrDocument,
		ObservationsTable,
		NotificationUsersDialog,
		ListObservations,
		DocumentsSection,
		ContractSheetTable,
		ApproversSection,
		DownloadFileBtn,
		AnnexSection,
		SupplierAssignmentDialog,
		LegalSubmitButton,
		SendToLegalBox,
		TemplateChangesTable,
	},
};
