<template>
	<v-container>
		<v-breadcrumbs :items="breadcrumbs" class="px-0 mb-4">
			<template v-slot:divider>
				<v-icon>mdi-chevron-right</v-icon>
			</template>
		</v-breadcrumbs>
		<read-agreement-page module="PROVIDER" />
	</v-container>
</template>
<script>
import ReadAgreementPage from '@/components/supplier/ReadAgreementPage/ReadAgreementPage.vue';

export default {
	name: 'PrecontractDetailView',

	data() {
		return {
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Proveedor',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'SupplierHome',
					},
				},
				{
					text: 'Documento',
					disabled: true,
					href: '/',
				},
			],
		};
	},
	components: { ReadAgreementPage },
};
</script>
